<template>
    <div class="subtitle-1">
        <div class="caption text-uppercase">{{ item.label }}</div>
        <div class="primary--text">{{ item.begin }}</div>
    </div>
</template>

<script>
export default {
    name: 'ScheduleColCall',
    props: [ 'item' ]
}
</script>

<style lang="scss">
    //* inset #9155fd 4px 0px 0px 0px */
</style>
